import React, { useEffect, useState } from "react";

import { ComboBox, ComboBoxBlurEvent, ComboBoxChangeEvent, ComboBoxCloseEvent, ComboBoxFilterChangeEvent, ComboBoxFocusEvent, ListItemProps } from "@progress/kendo-react-dropdowns";
import { IEntity } from "@selas/models";

import { getEntity } from "./utils";

import "./searchBox.scss";

export interface ISearchBoxProps<T extends IEntity> {
	name: string;
	entities: T[];
	entityId: number;
	entity: T;
	required?: boolean;
	textField: string;
	disabled?: boolean;
	header?: React.ReactNode;
	itemRender?: (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => React.ReactNode;
	onFilterChange: (event: ComboBoxFilterChangeEvent) => void;
	onFocus?: (event: ComboBoxFocusEvent) => void;
	onBlur?: (event: ComboBoxBlurEvent) => void;
	onClose: (event: ComboBoxCloseEvent) => void;
	onClear: () => void;
	focusOnRender?: boolean;
	isLoading?: boolean;
	myRef?: React.RefObject<ComboBox>;
}

function SearchBox<T extends IEntity>(props: ISearchBoxProps<T>): React.ReactElement {
	const [entity, setEntity] = useState(getEntity(props.entities, props.entityId, props.entity));
	const [filter, setFilter] = useState(null);

	useEffect(() => {
		setEntity(getEntity(props.entities, props.entityId, props.entity));
	}, [props.entities, props.entityId, props.entity]);

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		setFilter(event.filter.value);
		props.onFilterChange(event);
	}

	function onBlur(event: ComboBoxBlurEvent): void {
		setFilter(null);
		if (props.onBlur) {
			props.onBlur(event);
		}
	}

	function onChange(event: ComboBoxChangeEvent): void {
		setFilter(null);
		if (!event.value) {
			setEntity(null);
			props.onClear();
		} else {
			setEntity(event.value);
		}
	}

	return (
		<div className="search-box">
			<i className={"las la-search searchIcon"} />
			<ComboBox
				ref={props.myRef}
				name={props.name}
				className={`full-width-field${props.isLoading ? "" : " hiddenTrigger"}`}
				data={props.entities}
				loading={props.isLoading}
				value={entity}
				required={props.required}
				textField={props.textField}
				onFilterChange={onFilterChange}
				filter={filter}
				onFocus={props.onFocus}
				onBlur={onBlur}
				onClose={props.onClose}
				onChange={onChange}
				dataItemKey="id"
				suggest
				filterable
				allowCustom={false}
				disabled={props.disabled}
				header={props.header}
				itemRender={props.itemRender}
			/>
		</div>
	);
}

export default SearchBox;
