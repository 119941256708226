import { Fragment, ReactElement } from "react";
import React from "react";

import { GridColumnProps } from "@progress/kendo-react-grid";

export interface GridPanelColumnProps extends Omit<GridColumnProps, "columnMenu"> {
	field: string;
	title: string;
	isDefault: boolean;
}

// eslint-disable-next-line no-empty-pattern
const GridPanelColumn = ({}: GridPanelColumnProps): ReactElement => <Fragment />;

export default GridPanelColumn;
