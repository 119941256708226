import React from "react";

import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";

import EnumDropDownList from "../../../editor/enumDropDownList";

function enumFilterCell<T>(data: unknown): React.FC<GridFilterCellProps> {
	const EnumFilterCell = (props: GridFilterCellProps) => {
		const { t } = useTranslation();

		function hasValue(value: T): boolean {
			return value !== undefined && value !== null && value.toString() !== "";
		}

		function onChange(event: DropDownListChangeEvent): void {
			const valueSelected: boolean = hasValue(event.target.value?.key);
			props.onChange({
				value: valueSelected ? event.target.value?.key : "",
				operator: valueSelected ? "eq" : "",
				syntheticEvent: event.syntheticEvent
			});
		}

		function onClearButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
			event.preventDefault();
			props.onChange({
				value: "",
				operator: "",
				syntheticEvent: event
			});
		}

		const selectedValue: T = props.value || "";
		return (
			<div className="k-filtercell">
				<EnumDropDownList enum={data} value={selectedValue} onChange={onChange} />
				<button
					className="k-button k-button-icon k-clear-button-visible k-filtercell-operator"
					title={t("grid.filterClearButton")}
					type="button"
					disabled={!hasValue(props.value)}
					onClick={onClearButtonClick}
				>
					<span className="k-icon k-i-filter-clear" />
				</button>
			</div>
		);
	};

	return EnumFilterCell;
}

export default enumFilterCell;
