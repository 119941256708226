import React, { useEffect, useState } from "react";

import {
	Upload,
	UploadFileInfo,
	UploadFileRestrictions,
	UploadFileStatus,
	UploadOnAddEvent,
	UploadOnBeforeRemoveEvent,
	UploadOnBeforeUploadEvent,
	UploadOnProgressEvent,
	UploadOnRemoveEvent,
	UploadOnStatusChangeEvent,
	UploadResponse
} from "@progress/kendo-react-upload";
import { IFileData } from "@selas/models";
import forEach from "lodash/forEach";

interface IProps {
	multiple?: boolean;
	initialFiles?: IFileData[];
	accept?: string;
	restrictions?: UploadFileRestrictions;
	showFileList?: boolean;
	onError?: (action: string) => void;
	fileUploaded: (response: UploadResponse) => void;
	fileRemoved?: (internalFileName: string) => void;
	onBeforeUpload?: (event: UploadOnBeforeUploadEvent) => void;
	onBeforeRemove?: (event: UploadOnBeforeRemoveEvent) => void;
	disabled?: boolean;
}

const ApiFileUpload: React.FC<IProps> = (props: IProps) => {
	const [files, setFiles] = useState<UploadFileInfo[]>([]);

	useEffect(() => {
		if (props.initialFiles) {
			const initalFiles: UploadFileInfo[] = props.initialFiles.map((file: IFileData) => {
				return {
					uid: file.internalFilename,
					name: file.filename,
					extension: file.filename.split(".").pop(),
					size: file.size,
					progress: 100,
					status: UploadFileStatus.Uploaded
				};
			});
			setFiles(initalFiles);
		}
	}, [props.initialFiles]);

	function onAdd(event: UploadOnAddEvent): void {
		setFiles(event.newState);
	}

	function onRemove(event: UploadOnRemoveEvent): void {
		setFiles(event.newState);
		if (props.fileRemoved) {
			props.fileRemoved(event.affectedFiles[0].uid);
		}
	}

	function onProgress(event: UploadOnProgressEvent): void {
		setFiles(event.newState);
	}

	function onBeforeRemove(event: UploadOnBeforeRemoveEvent) {
		if (props.onBeforeRemove) {
			props.onBeforeRemove(event);
		}
		forEach(event.files, (file: UploadFileInfo) => {
			event.additionalData.internalFileName = file.uid;
		});
	}

	function onStatusChange(event: UploadOnStatusChangeEvent): void {
		const newFile: UploadFileInfo = event.affectedFiles[0];
		if (newFile.status === UploadFileStatus.Uploaded) {
			newFile.uid = event.response.response.internalFilename;
			props.fileUploaded(event.response);
		} else if (props.onError && (newFile.status === UploadFileStatus.UploadFailed || newFile.status === UploadFileStatus.RemoveFailed)) {
			props.onError(newFile.status === UploadFileStatus.UploadFailed ? "Upload" : "Remove");
		}
		setFiles(event.newState);
	}

	return (
		<Upload
			batch={false}
			multiple={props.multiple}
			autoUpload
			showFileList={props.showFileList}
			accept={props.accept}
			restrictions={props.restrictions}
			showActionButtons
			withCredentials
			files={files}
			saveUrl="/api/files"
			saveField="file"
			saveMethod="POST"
			removeUrl={props.onBeforeRemove ? "/api/files" : undefined}
			removeMethod={props.onBeforeRemove ? "DELETE" : undefined}
			onAdd={onAdd}
			onRemove={props.onBeforeRemove ? onRemove : undefined}
			onProgress={onProgress}
			onStatusChange={onStatusChange}
			onBeforeUpload={props.onBeforeUpload}
			onBeforeRemove={onBeforeRemove}
			disabled={props.disabled}
		/>
	);
};

export default ApiFileUpload;
