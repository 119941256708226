import React, { Dispatch, useEffect, useReducer, useState } from "react";

import { MultiSelect, MultiSelectProps } from "@progress/kendo-react-dropdowns";
import { TagData } from "@progress/kendo-react-dropdowns/dist/npm/MultiSelect/TagList";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { IAction, IEntity } from "@selas/models";
import { createEntityReducer, derender, getInitialState, hideLoader, render, showLoader } from "@selas/state-management";
import { newKey } from "@selas/utils";
import { useDispatch } from "react-redux";

import { IAddEntityScreenConfiguration, IAddEntityScreenProps, IEditEntityScreenConfiguration, IEditEntityScreenProps, isAddScreen, isEditScreen, isObject } from "./utils/types";

import "./manageableMultiSelect.scss";

interface IProps<TEntity extends IEntity, TAddScreenExtraProps = undefined, TEditScreenExtraProps = undefined> {
	addScreen: React.ComponentType<IAddEntityScreenProps<TEntity>> | IAddEntityScreenConfiguration<TEntity, TAddScreenExtraProps>;
	editScreen: React.ComponentType<IEditEntityScreenProps<TEntity>> | IEditEntityScreenConfiguration<TEntity, TEditScreenExtraProps>;
	fieldLabel: React.ReactNode;
	setEntity: (record: TEntity) => void;
	getByIdEndpoint?: string;
	readOnly: boolean;
}

function ManageableMultiSelect<TEntity extends IEntity, TAddScreenExtraProps = undefined, TEditScreenExtraProps = undefined>(
	props: MultiSelectProps & IProps<TEntity, TAddScreenExtraProps, TEditScreenExtraProps>
): React.ReactElement {
	const [editorKey, setEditorKey] = useState("");
	const [state, dispatch] = useReducer(createEntityReducer<TEntity>(props.getByIdEndpoint), getInitialState<TEntity>());
	const ApiService: ApiCommunicator = useApiService();
	const reduxDispatch: Dispatch<IAction> = useDispatch();

	useEffect(() => {
		if (!state.isEntityLoading && state.entity) {
			props.setEntity(state.entity);
			if (editorKey) {
				reduxDispatch(derender(editorKey));
			}
			reduxDispatch(hideLoader());
		} else if (state.isEntityLoading) {
			reduxDispatch(showLoader());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.isEntityLoading, state.entity]);

	function addRecord(): void {
		const key: string = newKey("add");
		const addScreenProps: IAddEntityScreenProps<TEntity> = {
			actionButtonClicked: (close: boolean, record?: TEntity) => editorActionButtonClicked(close ? key : null, record),
			hideActive: true
		};
		if (isAddScreen(props.addScreen)) {
			reduxDispatch(render(key, props.addScreen, addScreenProps));
		} else {
			reduxDispatch(
				render(key, props.addScreen.screen, {
					...addScreenProps,
					...props.addScreen.extraProps
				})
			);
		}
	}

	function editRecord(recordId: number): void {
		const key: string = newKey("edit");
		const editScreenProps: IEditEntityScreenProps<TEntity> = {
			recordId: recordId,
			readonly: !props.readOnly,
			actionButtonClicked: (close: boolean, record?: TEntity) => editorActionButtonClicked(close ? key : null, record),
			hideActive: true
		};
		if (isEditScreen(props.editScreen)) {
			reduxDispatch(render(key, props.editScreen, editScreenProps));
		} else {
			editScreenProps.readonly = editScreenProps.readonly && !props.editScreen.isAllowed;
			reduxDispatch(
				render(key, props.editScreen.screen, {
					...editScreenProps,
					...(isObject(props.editScreen.extraProps) ? props.editScreen.extraProps : undefined)
				})
			);
		}
	}

	function editorActionButtonClicked(key: string, record?: TEntity): void {
		if (!props.getByIdEndpoint || !record) {
			if (record) {
				props.setEntity(record);
			}
			if (key) {
				reduxDispatch(derender(key));
			}
		} else {
			setEditorKey(key);
			ApiService.callApi(dispatch, props.getByIdEndpoint, "GET", { id: record.id });
		}
	}

	return (
		<div className="k-form-field manageableMutiSelect">
			<span className="manageable-field-label">
				{!props.readOnly && (props.addScreen as IAddEntityScreenConfiguration<TEntity, TAddScreenExtraProps>)?.isAllowed !== false && <i className="las la-plus" onClick={addRecord} />}
				{props.fieldLabel}
			</span>
			<div className={props.filterable && "search-box"}>
				{props.filterable && <i className={"las la-search searchIcon"} />}
				<MultiSelect
					{...props}
					tagRender={(tagData: TagData, li: React.ReactElement<HTMLLIElement>) => {
						const element: React.ReactElement<HTMLLIElement> = React.cloneElement(
							li,
							li.props,
							li.props.children,
							!props.readOnly && (props.editScreen as IEditEntityScreenConfiguration<TEntity, TEditScreenExtraProps>)?.isAllowed !== false && (
								<i className="las la-pencil-alt" onClick={() => editRecord(tagData.data[0].id)} />
							)
						);
						return element;
					}}
				/>
			</div>
		</div>
	);
}

export default ManageableMultiSelect;
