import { Reducer } from "react";

import { IAction } from "@selas/models";
import { AxiosError, AxiosResponse } from "axios";

import { GridPanelConfiguration, GridPanelConfigurationState, intitialGridPanelConfigurationState } from "../../gridpanelConfigurationStates";

export default function createGridPanelConfigurationReducer(
	endpointName: string,
	initialState: GridPanelConfigurationState = intitialGridPanelConfigurationState
): Reducer<GridPanelConfigurationState, IAction> {
	return (state: GridPanelConfigurationState = initialState, action: IAction): GridPanelConfigurationState => {
		switch (action.type) {
			case "REQUEST_GET_" + endpointName: {
				state = {
					...state,
					isLoading: true,
					loadError: null
				};

				break;
			}
			case "REQUEST_FAILURE_GET_" + endpointName: {
				const payload: { error: AxiosError } = action.payload as { error: AxiosError };
				state = {
					...state,
					loadError: payload.error
				};
				break;
			}
			case "REQUEST_SUCCESS_GET_" + endpointName: {
				const payload: { response: AxiosResponse<GridPanelConfiguration> } = action.payload as { response: AxiosResponse<GridPanelConfiguration> };
				if (payload && payload.response && payload.response.data) {
					state = {
						...state,
						gridPanelConfiguration: payload.response.data
					};
				}
				break;
			}
			case "REQUEST_END_GET_" + endpointName:
				state = {
					...state,
					isLoading: false
				};
				break;
			case "REQUEST_PUT_" + endpointName:
				state = {
					...state,
					isSaving: true,
					savedGridPanelConfiguration: null,
					saveError: null
				};
				break;
			case "REQUEST_SUCCESS_PUT_" + endpointName: {
				const payload: { response: AxiosResponse<GridPanelConfiguration> } = action.payload as { response: AxiosResponse<GridPanelConfiguration> };
				state = {
					...state,
					savedGridPanelConfiguration: payload.response.data
				};
				break;
			}
			case "REQUEST_FAILURE_PUT_" + endpointName: {
				const payload: { error: AxiosError } = action.payload as { error: AxiosError };
				state = {
					...state,
					saveError: payload.error
				};
				break;
			}
			case "REQUEST_END_PUT_" + endpointName:
				state = {
					...state,
					isSaving: false
				};
				break;
		}
		return state;
	};
}
