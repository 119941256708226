import React, { useState } from "react";

import { isNullOrEmpty } from "@selas/utils";
import compact from "lodash/compact";
import each from "lodash/each";

import Tab, { IProps as ITabProps } from "./tab";

interface IProps {
	style?: React.CSSProperties;
	tabBarStyle?: React.CSSProperties;
	tabStyle?: React.CSSProperties;
	children: React.ReactElement<ITabProps>[];
}

const TabPanel: React.FC<IProps> = (props: IProps) => {
	const [selectedTab, setSelectedTab] = useState(0);

	const cleanChildren: React.ReactElement<ITabProps>[] = compact(props.children);
	const tabs: React.ReactElement[] = [];
	const panels: React.ReactElement[] = [];

	each(cleanChildren, (tab: React.ReactElement<ITabProps>, index: number) => {
		tabs.push(
			<li
				key={"tab-" + tab.props.reactKey}
				className={selectedTab === index ? "active" : ""}
				onClick={
					selectedTab === index
						? undefined
						: () => {
								setSelectedTab(index);
						  }
				}
			>
				{tab.props.label}
			</li>
		);
		let panelClassname: string = selectedTab === index ? "" : "hidden";
		if (!isNullOrEmpty(tab.props.className)) {
			panelClassname += (!isNullOrEmpty(panelClassname) ? " " : "") + tab.props.className;
		}
		panels.push(
			<div key={"tabBody-" + tab.props.reactKey} className={panelClassname}>
				{tab}
			</div>
		);
	});

	return (
		<div className="d-flex flex-column" style={props.style}>
			{tabs.length > 1 ? (
				<div className="tabBar" style={props.tabBarStyle}>
					<ul>{tabs}</ul>
				</div>
			) : (
				<div className="titleBar" style={props.tabBarStyle}>
					{cleanChildren[0].props.label}
				</div>
			)}
			<div className="flex-grow-1 tabBarContainer" style={props.tabStyle}>
				{panels}
			</div>
		</div>
	);
};

export default TabPanel;
export { Tab };
