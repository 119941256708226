import React from "react";

import { IEntity } from "@selas/models";

interface IAddEntityScreenProps<TEntity extends IEntity> {
	actionButtonClicked: (close: boolean, record?: TEntity) => void;
	hideActive?: boolean;
}

interface IEditEntityScreenProps<TEntity extends IEntity> extends IAddEntityScreenProps<TEntity> {
	recordId: number;
	readonly: boolean;
}

interface IEntityLinkConfiguration {
	link: string;
	openInNewTab?: boolean;
	isAllowed?: boolean;
}

interface IAddEntityScreenConfiguration<TEntity extends IEntity, TExtraProps = undefined> {
	screen: React.ComponentType<IAddEntityScreenProps<TEntity> & TExtraProps>;
	extraProps?: TExtraProps;
	isAllowed?: boolean;
}

interface IEditEntityScreenConfigurationBase<TEntity extends IEntity, TExtraProps = undefined> {
	screen: React.ComponentType<IEditEntityScreenProps<TEntity> & TExtraProps>;
	isAllowed?: boolean | ((dataItem: TEntity) => boolean);
}

interface IEditEntityScreenConfiguration<TEntity extends IEntity, TExtraProps = undefined> extends IEditEntityScreenConfigurationBase<TEntity, TExtraProps> {
	extraProps?: TExtraProps;
}

interface IEditEntityScreenFunctionConfiguration<TEntity extends IEntity, TExtraProps = undefined> extends IEditEntityScreenConfigurationBase<TEntity, TExtraProps> {
	extraProps?: (entity: TEntity) => TExtraProps;
}

function isObject<TEntity extends IEntity, TExtraProps>(extraProps: TExtraProps | ((entity: TEntity) => TExtraProps)): extraProps is TExtraProps {
	return typeof extraProps === "object" || typeof extraProps === "undefined";
}

interface IDeleteEntityConfiguration<TEntity> {
	urlArguments?: Record<string, unknown> | ((entity: TEntity) => Record<string, unknown>);
	isAllowed?: boolean | ((dataItem: TEntity) => boolean);
}

function isEntityLinkConfiguration(link: string | IEntityLinkConfiguration): link is IEntityLinkConfiguration {
	return (link as IEntityLinkConfiguration).link !== undefined;
}

function isAddScreen<TEntity extends IEntity, TExtraProps = undefined>(
	screenProps: React.ComponentType<IAddEntityScreenProps<TEntity>> | IAddEntityScreenConfiguration<TEntity, TExtraProps>
): screenProps is React.ComponentType<IAddEntityScreenProps<TEntity>> {
	return (screenProps as React.ComponentType<IAddEntityScreenProps<TEntity>>).propTypes?.actionButtonClicked !== undefined;
}

function isEditScreen<TEntity extends IEntity, TExtraProps = undefined>(
	screenProps: React.ComponentType<IEditEntityScreenProps<TEntity>> | IEditEntityScreenConfiguration<TEntity, TExtraProps> | IEditEntityScreenFunctionConfiguration<TEntity, TExtraProps>
): screenProps is React.ComponentType<IEditEntityScreenProps<TEntity>> {
	return (screenProps as React.ComponentType<IEditEntityScreenProps<TEntity>>).propTypes?.actionButtonClicked !== undefined;
}

function isDeleteConfiguration<TEntity>(deleteConfig: boolean | IDeleteEntityConfiguration<TEntity>): deleteConfig is IDeleteEntityConfiguration<TEntity> {
	return typeof deleteConfig !== "boolean";
}

function isAddEntityLink<TEntity extends IEntity, ExtraProps = undefined>(
	prop: string | IEntityLinkConfiguration | React.ComponentType<IAddEntityScreenProps<TEntity>> | IAddEntityScreenConfiguration<TEntity, ExtraProps>
): prop is string | IEntityLinkConfiguration {
	return typeof prop === "string" || (prop as IEntityLinkConfiguration).link !== undefined;
}

function isEditEntityLink<TEntity extends IEntity, TExtraProps = undefined>(
	prop:
		| string
		| IEntityLinkConfiguration
		| React.ComponentType<IEditEntityScreenProps<TEntity>>
		| IEditEntityScreenConfiguration<TEntity, TExtraProps>
		| IEditEntityScreenFunctionConfiguration<TEntity, TExtraProps>
): prop is string | IEntityLinkConfiguration {
	return typeof prop === "string" || (prop as IEntityLinkConfiguration).link !== undefined;
}
export {
	IAddEntityScreenConfiguration,
	IAddEntityScreenProps,
	IDeleteEntityConfiguration,
	IEditEntityScreenConfiguration,
	IEditEntityScreenFunctionConfiguration,
	IEditEntityScreenProps,
	IEntityLinkConfiguration,
	isAddEntityLink,
	isAddScreen,
	isDeleteConfiguration,
	isEditEntityLink,
	isEditScreen,
	isEntityLinkConfiguration,
	isObject
};
