import React from "react";

import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import find from "lodash/find";
import { useTranslation } from "react-i18next";

const NullableBooleanFilterCell: React.FC<GridFilterCellProps> = (props: GridFilterCellProps) => {
	const { t } = useTranslation();

	const data: { text: string; value: boolean | string }[] = [
		{ text: t("grid.filterBooleanAll"), value: "" },
		{ text: t("grid.filterIsTrue"), value: true },
		{ text: t("grid.filterIsFalse"), value: false },
		{ text: t("grid.filterBooleanIsNull"), value: null }
	];

	function hasValue(value: boolean | string): boolean {
		return value !== undefined && value !== "";
	}

	function onChange(event: DropDownListChangeEvent): void {
		const valueSelected: boolean = hasValue(event.target.value?.value);
		props.onChange({
			value: valueSelected ? event.target.value?.value : "",
			operator: valueSelected ? "eq" : "",
			syntheticEvent: event.syntheticEvent
		});
	}

	function onClearButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
		event.preventDefault();
		props.onChange({
			value: "",
			operator: "",
			syntheticEvent: event
		});
	}

	return (
		<div className="k-filtercell">
			<DropDownList data={data} dataItemKey="value" textField="text" onChange={onChange} value={find(data, { value: props.value })} />
			<div className="k-filtercell-operator">
				<button className={"k-button k-button-icon" + (hasValue(props.value) ? " k-clear-button-visible" : "")} title={t("grid.filterClearButton")} type="button" onClick={onClearButtonClick}>
					<span className="k-icon k-i-filter-clear" />
				</button>
			</div>
		</div>
	);
};

export default NullableBooleanFilterCell;
